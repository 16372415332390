import axios from "axios";
import React, { ChangeEvent } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import toast from "react-hot-toast";

import AddBlocksButton from "../AddBlocksButton";
import TextBlock from "../Blocks/TextBlock";
import PictureBlock from "../Blocks/PictureBlock";
import QuoteBlock from "../Blocks/QuoteBlock";
import YouTubeBlock from "../Blocks/YouTubeBlock";
import VideoBlock from "../Blocks/VideoBlock";
import CarouselBlock from "../Blocks/CarouselBlock";

import { ReactComponent as DragDropIco } from "../../assets/ico/dragDrop.svg";

import styles from "./Block.module.scss";

import {
  BlockProps,
  PictureBlockProps,
  QuoteBlockProps,
  VideoBlockProps,
} from "../../store/ownDrafts/ownDraftsSlice";

type BlockComponentProps = {
  id: string;
  langValue: string;
  token: string;
  index: number;
  obj: BlockProps;
  blocksValues: BlockProps[];
  draftTitle: string;
  setBlocksValues: React.Dispatch<React.SetStateAction<BlockProps[]>>;
  saveDraft: (newBlocks?: BlockProps[], bannerUrl?: string) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const Block: React.FC<BlockComponentProps> = ({
  id,
  langValue,
  token,
  index,
  obj,
  blocksValues,
  draftTitle,
  setBlocksValues,
  saveDraft,
  setIsLoading,
}) => {
  const [isLoadingCarousel, setIsLoadingCarousel] = React.useState(false);

  const updateQuoteValue = (idx: number, value: string, field: string) => {
    const values = blocksValues.map((obj, index) => {
      if (idx === index) {
        if (typeof obj.data === "object") {
          return {
            ...obj,
            data: {
              ...(obj.data as QuoteBlockProps),
              [field]: value,
            },
          };
        }
      }
      return obj;
    });
    setBlocksValues(values);
  };

  const updateAltValue = (idx: number, value: string) => {
    const values = blocksValues.map((obj, index) => {
      if (idx === index) {
        return {
          ...obj,
          data: {
            ...(obj.data as PictureBlockProps),
            alt: value,
          },
        };
      }
      return obj;
    });
    setBlocksValues(values);
  };

  const updateTextValue = (idx: number, value: string) => {
    const values = blocksValues.map((obj, index) => {
      if (idx === index) {
        if (typeof obj.data === "string") {
          return { ...obj, data: value };
        }
      }
      return obj;
    });
    setBlocksValues(values);
  };

  const updateYouTubeValue = (idx: number, value: string) => {
    const values = blocksValues.map((obj, index) => {
      if (idx === index) {
        const path = value.split("/")[2];
        if (path === "youtube.com" || path === "youtu.be") {
          return {
            ...obj,
            data: {
              ...(obj.data as VideoBlockProps),
              url: value,
            },
          };
        } else {
          toast.error("Это не похоже на ссылку видео из ютуба!");
        }
      }
      return obj;
    });
    setBlocksValues(values);
  };

  const updateCarouselPhotoAlt = (
    idx: number,
    value: string,
    photoIndex: number
  ) => {
    const values = blocksValues.map((obj, index) => {
      if (idx === index) {
        return {
          ...obj,
          data: (obj.data as PictureBlockProps[]).map((picture, indexPhoto) => {
            if (indexPhoto === photoIndex) {
              return {
                ...picture,
                alt: value,
              };
            }
            return picture;
          }),
        };
      }
      return obj;
    });
    console.log(values);
    setBlocksValues(values);
  };

  const addMedia = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `/api/v1/admin/draft/${id}/${langValue}/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      setIsLoading(false);
      return data;
    } catch (err) {
      console.log(err);
      toast.error("Ошибка при загрузке медиа! Попробуйте позже");
      setIsLoading(false);
    }
  };

  const addCarouselPhoto = async (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setIsLoadingCarousel(true);
    const files = event.target.files;

    if (files?.length === 0) return;

    if (files === null) return;

    const formData = new FormData();
    formData.append("file", files[0]);

    const data = await addMedia(formData);

    if (!data) {
      return;
    }

    let allItems = [...blocksValues];
    let newItem = {
      ...allItems[index],
      data: [
        ...(allItems[index].data as PictureBlockProps[]),
        { type: data.media_type, url: data.public_url, alt: null },
      ],
    };
    allItems[index] = newItem;

    setBlocksValues(allItems);
    saveDraft(allItems);
    setIsLoadingCarousel(false);
  };

  const deleteBlock = async (blockIndex: number) => {
    let newBlocks = [...blocksValues];
    newBlocks = newBlocks.filter((obj, index) => index !== blockIndex);
    setBlocksValues(newBlocks);
    saveDraft(newBlocks);
  };

  const deleteMediaBlock = async (url: string, blockIndex: number) => {
    setIsLoading(true);
    await deleteMedia(url);
    deleteBlock(blockIndex);
    setIsLoading(false);
  };

  const deleteMedia = async (url: string) => {
    try {
      await axios.delete(`/api/v1/admin/draft/${id}/media`, {
        data: {
          file_link: url,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    } catch (err) {
      console.log(err);
      toast.error("Ошибка при удалении блока");
    }
  };

  const deleteCarouselPhoto = async (
    url: string,
    blockIndex: number,
    photoIndex: number
  ) => {
    await deleteMedia(url);
    let newBlocks = [...blocksValues];
    let photoArr = newBlocks[blockIndex].data as PictureBlockProps[];
    if (photoArr.length > 1) {
      photoArr = photoArr.filter((obj, index) => photoIndex !== index);

      newBlocks[blockIndex] = {
        ...newBlocks[blockIndex],
        data: photoArr,
      };
    } else {
      newBlocks = newBlocks.filter((obj, index) => index !== blockIndex);
    }

    setBlocksValues(newBlocks);
    saveDraft(newBlocks);
  };

  return (
    <Draggable draggableId={index.toString()} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          className={styles.block}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={styles.addBlocksPlus}>
            <AddBlocksButton
              index={index}
              blocksValues={blocksValues}
              draftTitle={draftTitle}
              setBlocksValues={setBlocksValues}
              addMedia={addMedia}
              saveDraft={saveDraft}
            />
          </div>
          <div className={styles.dragDropIco}>
            <DragDropIco />
          </div>
          {obj.type === "TEXT" && (
            <TextBlock
              index={index}
              value={obj.data as string}
              updateInputValue={updateTextValue}
            />
          )}
          {obj.type === "PICTURE" && (
            <PictureBlock
              index={index}
              updateInputValue={updateAltValue}
              src={(obj.data as PictureBlockProps).url}
              altValue={
                (obj.data as PictureBlockProps).alt !== null
                  ? (obj.data as PictureBlockProps).alt
                  : ""
              }
            />
          )}
          {obj.type === "QUOTE" && (
            <QuoteBlock
              index={index}
              updateInputValue={updateQuoteValue}
              quoteValue={(obj.data as QuoteBlockProps).text}
              authorName={(obj.data as QuoteBlockProps).author_name}
              linkValue={(obj.data as QuoteBlockProps).link}
            />
          )}
          {obj.type === "VIDEO" &&
          (obj.data as VideoBlockProps).provider === "CUSTOM" ? (
            <VideoBlock value={(obj.data as VideoBlockProps).url} />
          ) : (
            (obj.data as VideoBlockProps).provider === "YOUTUBE" && (
              <YouTubeBlock
                index={index}
                updateInputValue={updateYouTubeValue}
                value={(obj.data as VideoBlockProps).url}
              />
            )
          )}
          {obj.type === "PICTURE_CAROUSEL" && (
            <CarouselBlock
              index={index}
              isLoading={isLoadingCarousel}
              updateInputValue={updateCarouselPhotoAlt}
              photos={obj.data as PictureBlockProps[]}
              addCarouselPhoto={addCarouselPhoto}
              deleteCarouselPhoto={deleteCarouselPhoto}
            />
          )}

          {obj.type !== "PICTURE_CAROUSEL" && (
            <div
              className={styles.deleteBlockIco}
              onClick={() =>
                obj.type === "PICTURE"
                  ? deleteMediaBlock((obj.data as PictureBlockProps).url, index)
                  : obj.type === "VIDEO" &&
                    (obj.data as VideoBlockProps).provider === "CUSTOM"
                  ? deleteMediaBlock((obj.data as VideoBlockProps).url, index)
                  : deleteBlock(index)
              }
            >
              X
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Block;
