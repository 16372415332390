import React from "react";

import styles from "./VideoBlock.module.scss";

type VideoBlockProps = {
    value: string
};

const VideoBlock: React.FC<VideoBlockProps> = ({value}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = value;
      videoRef.current.load();
    }
  }, [value]);

  return (
    <div className={styles.videoBlock}>
      <video ref={videoRef} controls width="250">
        <source src={value} />
      </video>
    </div>
  );
};

export default VideoBlock;
