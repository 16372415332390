interface Config {
    apiUrl: string;
}

// Create the config object, pulling values from environment variables
const config: Config = {
    apiUrl: process.env.REACT_APP_API_URL || '', // fallback to an empty string if undefined
};

export default config;
